// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

import 'bootstrap'
import '../stylesheets/application';
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js";

// require('ekko-lightbox');
require("./src/ekko-lightbox.js");
import "./src/ekko-lightbox.scss";

require('packs/search')
require('packs/gallery')
require('packs/pagy')
import lozad from 'lozad'

$(function(){
  $('input.datepicker').datepicker({
    format: "dd/mm/yyyy",
    clearBtn: true,
    language: "pt-BR",
    autoclose: true,
    todayHighlight: true
  });

  $('body').on('contextmenu', 'img', function(e) {
    return false;
  });
  const observer = lozad('.lozad', {
    rootMargin: '100px 0px', // syntax similar to that of CSS Margin
    threshold: 0.1 // ratio of element convergence
  });
  observer.observe();

  var cordel = $('#cordel-advanced-search');
  var fortuna_critica = $('#fortuna-critica-advanced-search');

  $('input[name=search_block]').on('change', function() {
    var radioVal = $(this).val();
    if(radioVal === 'cordel') {
      cordel.removeClass('d-none');
      fortuna_critica.addClass('d-none');
    }
    else {
      fortuna_critica.removeClass('d-none');
      cordel.addClass('d-none');
    }
  })

  var radioVal = $('input[name="search_block"]:checked').val();
  if(radioVal === 'cordel') {
    cordel.removeClass('d-none');
    fortuna_critica.addClass('d-none');
  }
  else {
    fortuna_critica.removeClass('d-none');
    cordel.addClass('d-none');
  }
});



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
